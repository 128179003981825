.messge400 {
  @extend .text-center;
  h1 {
    font-family: 'Open Sans', sans-serif;
    margin-top: 25px;
    color: #8d8988;
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    @include respond-to("md") {
      font-size: 50px;
      line-height: 63px;
    }

    span {
      font-size: 40px;
      color: #f1734c;
      font-weight: 300;
      @include respond-to("md") {
        font-size: 80px;
      }
    }
  }
  p {
    font-size: 14px;
    line-height: 21px;
    color: #9c9694;
    max-width: 620px;
    padding: 0 20px;
    margin: 0 auto;
    display: block;
  }
  @media screen and (min-width: 1200px) {
    h1 {
      margin-top: 86px;
      margin-bottom: 30px;
      font-size: 81px;
      span {
        font-size: 105px;
      }
    }
  }
}

.cloudWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  overflow: hidden;
}

.cloud {
  position: absolute;
  z-index: 121;
  will-change: transform;
}

.cloud-1 {
  width: percentage(320 / 1900);
  top: 50px;
  animation: cloud 20s linear infinite;
  left: -(percentage(320 / 1900));
}

.cloud-2 {
  width: percentage(517 / 1900);
  top: 250px;
  animation: cloud 38s linear infinite;
  left: -(percentage(517 / 1900));
}

.cloud-3 {
  width: percentage(466 / 1900);
  top: 150px;
  animation: cloud 27s linear 6s infinite;
  left: -(percentage(466 / 1900));
}

.cloud-4 {
  width: percentage(508 / 1900);
  top: 10%;
  animation: cloud 40s linear 4s infinite;
  left: -(percentage(508 / 1900));
}

.cloud {
  background-repeat: no-repeat;
  background-size: 100%;
}

@keyframes cloud {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(700%);
  }
}


@media screen and (max-width: 1024px) {
  @keyframes cloud {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(900%);
    }
  }
}

@keyframes sun {
  50% {
    box-shadow: 0px 0px 20px 10px #f0ffc8;
  }
  100% {
    box-shadow: 0px 0px 60px 30px #f0ffc8;
  }
}


.landing-1 {
  min-height: 1200px;
  @include respond-to("sm") {
    min-height: 950px;
  }

  @include respond-to("md") {
    min-height: 1200px;
  }

  @include respond-to("lg") {
    min-height: 1500px;
  }
}

//
// 400 start
.wrap400 {
  min-height: 100%;
  height: auto !important;
  background: #A3E7FF;
  .messge400 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.charecter-3 {
  position: absolute;
  display: block;
  top: 50%;
  width: 100%;
  will-change: transform;
  @media screen and (min-width: 1115px) {
    max-width: 1115px;
    margin-top: 306px;
    position: relative;
    margin: 0 auto;
    margin-top: 350px;
  }
  @media screen and (max-height: 480px) {
    margin-top: 5%;
  }
}

.eye3 {
  position: absolute;
  top: percentage(224 / 498);
  left: percentage(457 / 1115);
  width: percentage(222 / 1115);
}

// 400 end