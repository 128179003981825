// Typography

// [converter] $parent hack
@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color;
  }
  a#{$parent}:hover {
    color: darken($color, 10%);
  }
}
