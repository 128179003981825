//
// 400-2 start

.wrap4002 {
  height: 100%;
  background: #A3E7FF;
  position: relative;
  @media screen and (min-width: 1200px) {
    min-height: 1080px;
  }
  @media screen and (max-width: 600px) {
    min-height: 750px;
  }
}

.messge400 {
  position: relative;
  z-index: 200;
  @extend .messge500;
  h1 {
    color: #448795;
  }
  p {
    color: #2e6b78;
    max-width: 830px;
  }
}

//$charecter5:527;
$charecter5: 527;

.charecter-5 {
  bottom: 40px;
  position: absolute;
  z-index: 50;
  left: 50%;
  width: 260px;
  margin-left: -130px;
  .charecter-5anim {
    animation: boat 3s linear 0s infinite;
  }
}

@keyframes boat {
  50% {
    transform: rotateZ(5deg) translateY(-5px);
  }
}


.waterflow {
  height: 190px;
  background: url(../images/water-flow.png) center top;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.fish {
  position: absolute;
  right: 0;
  bottom: 0;
  width: percentage(178 / $charecter5);
  bottom: -14px;
}

.bubble {
  opacity: 0;
  width: percentage(18 / 178);
  position: absolute;
}

.bubble-1 {
  left: percentage(45 / 178);
  bottom: percentage(18 / 178);
  animation: buble1 3s linear 0s infinite;
}

.bubble-2 {
  width: percentage(12 / 178);
  left: percentage(35 / 178);
  bottom: percentage(18 / 178);
  animation: buble1 3s linear 2s infinite;
}

.bubble-3 {
  width: percentage(12 / 178);
  left: percentage(52 / 178);
  bottom: percentage(18 / 178);
  animation: buble1 3s linear 4s infinite;
}

@keyframes buble1 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    bottom: percentage(150 / 178);
  }
}


.eye5 {
  position: absolute;
  width: percentage(206 / 527);
  left: percentage(179 / 527);
  top: percentage(161 / 698);
}

@media screen and (min-width: 900px) {
  .charecter-5 {
    width: 370px;
    margin-left: -180px;
  }
  .waterflow {
    height: 230px;
  }
}

@media screen and (min-width: 1200px) {
  .charecter-5 {
    width: 527px;
    margin-left: -263.5px;
  }
  .waterflow {
    height: 280px;
  }
}

// 400-2 end