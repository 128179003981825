// Media query Brekpoints
$breakpoints: ("xs": $screen-xs, "sm": $screen-sm, "md": $screen-md, "lg": $screen-lg);

@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);
  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Please make sure it is defined in `$breakpoints` map.";
  }
}

/* 
@include respond-to('xs') {
    // Sass
}
 */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Space out content a bit */
body {
  font-family: 'Open Sans', sans-serif;
  padding: 0;
}

// global style
.row-flex {
  width: 100%;
  float: left;
}

img {
  max-width: 100%;
  height: auto;
}

html,
body {
  height: 100%;
  min-width: 320px;
}

.page_overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #425051;
  z-index: 150;
}

.loader-inner {
  position: fixed;
  top: 50%;
  left: 50%;
}